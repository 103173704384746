var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('nav',{staticClass:"breadcrumb is-large",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'negotiatorStart', params: { id: _vm.$route.params.id } }}},[_c('span',[_vm._v("Start")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'negotiatorPayLess',
            params: { id: _vm.$route.params.id }
          }}},[_c('span',[_vm._v("Pay Less")])])],1),_c('li',{staticClass:"is-active"},[_c('router-link',{attrs:{"to":{
            name: 'negotiatorPayLessFail',
            params: { id: _vm.$route.params.id }
          }}},[_c('span',[_vm._v("Offer")])])],1)])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_c('span',[_vm._v(_vm._s(_vm.instanceConfig.offerDeclinedTitle))])])]),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.negotiatorSOD2Results["catch-all"].message)+" ")])])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button is-success",attrs:{"tag":"button","to":{
          name: 'paymentForm',
          params: { id: _vm.$route.params.id },
          query: {
            action: 'negotiator',
            needsSD: false,
            sdAccepted: false
          }
        }}},[_vm._v("Accept")]),_c('button',{staticClass:"button"},[_vm._v("Pay Over Time")]),_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'makePayment', params: { id: _vm.$route.params.id } },"tag":"button"}},[_vm._v("Cancel")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }